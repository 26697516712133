import React from 'react';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import { BsArrowRightCircle, BsFillEnvelopeCheckFill, BsTelephoneOutbound } from 'react-icons/bs'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import logo from '../../../images/support/2.svg'
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { toast } from 'react-toastify';



const courseData = [
    {
        title: 'oikko.awo@gmail.com',
        disc: 'You can email us directly if you want.',
        logo: <BsFillEnvelopeCheckFill />
    },
    {
        title: '+880 1738 733 745',
        disc: 'Call for any need',
        logo: <BsTelephoneOutbound />
    },
    {
        title: 'Tetulia, Panchagarh, Bangladesh',
        disc: "Tetulia is our main location",
        logo: <FaMapMarkerAlt />
    }
]

const Contact = () => {
    const [loadding, setLoadding] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const date = new Date().toDateString();

    //send Data in Database 
    const onSubmit = data => {
        const sendData = {
            userName: data?.userName,
            email: data?.email,
            phone: data?.phone,
            disc: data?.disc,
            time: date
        }
        setLoadding(true)
        fetch(`${backendApi}/question/postQuestion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendData)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message);
                    reset()
                    setLoadding(false)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }

    return (
        <section className='contact-sec overflow-hidden'>
            <div className='container mx-auto my-[130px] md:px-12 px-5'>
                <div className="contact-title text-center mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]   font-extrabold text-slate-950 uppercase'>Contact <span className='text-blue-500'>with</span> us</h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>Live chat facility available for 24/7 support</h2>
                    </RubberBand>
                </div>

                <div className="contact-main grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                    <>
                        <Zoom>
                            {
                                courseData?.map((course, index) => {
                                    const { title, disc, logo } = course;
                                    return (
                                        <div className="overflow-hidden shadow-lg shadow-gray-400 rounded-2xl p-4 hover:cursor-pointer transition duration-700 ease-in-out border border-transparent hover:border hover:border-blue-400" key={index}>
                                            <div className="contact-box">
                                                <h2 className='w-14 h-14 rounded-full bg-blue-400 mx-auto text-white text-[30px] my-4 flex items-center justify-center'>{logo}</h2>
                                                <div className="cotact pb-8">
                                                    <h2 className='text-slate-800 text-center font-semibold  text-[18px]'>{title}</h2>
                                                    <h2 className=' text-gray-500 text-center font-medium  text-[16px]'>{disc}</h2>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </Zoom>
                    </>
                </div>

                <div className="contact-main grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5 my-24 items-center">
                    <div className="contact-left">
                        <Slide left>
                            <div className="contact-form shadow-lg shadow-gray-400 rounded-xl p-4">
                                <h2 className='text-blue-500 text-[25px]  font-semibold mb-5'>Do you have any question?</h2>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5">
                                        <div class="mb-3">
                                            <label htmlFor="nn" className='text-gray-600 font-medium '>Full Name</label>
                                            <input {...register("userName", { required: true })} type='text' id='nn' placeholder='Enter Your Name' className="mt-2 flex h-12 text-slate-800 w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                            {errors.userName && <span className='text-red-400 mt-1 '>This field is required</span>}
                                        </div>
                                        <div class="mb-3">
                                            <label htmlFor="mm" className='text-gray-600 font-medium '>Email Address</label>
                                            <input {...register("email", { required: true })} type='email' id='mm' placeholder='Email address' className="mt-2 flex h-12 text-slate-800 w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                            {errors.email && <span className='text-red-400 mt-1 '>This field is required</span>}
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label htmlFor="pn" className='text-gray-600 font-medium '>Phone</label>
                                        <input {...register("phone", { required: true })} type='number' id='pn' placeholder='Mobile/Phone' className="mt-2 flex h-12 text-slate-800 w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.phone && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                    <div class="mb-3">
                                        <label htmlFor="ddd" className='text-gray-600 font-medium '>Write Message</label>
                                        <textarea {...register("disc", { required: true })} type='text' id='ddd' rows='5' placeholder='Message Type Here...' className="mt-2 flex  text-slate-800 w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.disc && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                    <div className="submit">
                                        {
                                            loadding ? <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' disabled>Message Sending wait.. <span className='loader'></span></button>
                                                :

                                                <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white from-[#3A5896] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Send Message <BsArrowRightCircle /></button>
                                        }
                                    </div>
                                </form>

                            </div>

                        </Slide>
                    </div>

                    <div className="contact-left">
                        <Slide right>
                            <img src={logo} alt="oikko" className='lg:h-[500px] mx-auto' />
                        </Slide>
                    </div>

                </div>
                {/* Google Map added  */}
                <div className="map my-24">
                    <h2 className='text-center text-[18px]  font-medium text-slate-700 mb-5'>See Our location in google map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28569.46511342375!2d88.33286638534007!3d26.48204758214087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e4599635d06b05%3A0x5d796b66aca2caa0!2sTetulia!5e0!3m2!1sen!2sbd!4v1682407493227!5m2!1sen!2sbd" className='w-full h-[300px] rounded-xl' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section >
    );
};

export default Contact;