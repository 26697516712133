import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import { FaClock } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import logo from '../../../images/loader.gif'
import RecentProject from './RecentProject';


const ProjectDetails = () => {
    const [project, setProject] = useState({});
    const [loadding, setLoadding] = useState(false);




    const { id } = useParams();

    useEffect(() => {
        setLoadding(true)
        fetch(`${backendApi}/project/noticeDetails/${id}`)
            .then(res => res.json())
            .then(data => {
                setLoadding(false);
                setProject(data)

            })
    }, [id])


    //Convert Html File 
    function createMarkup(theExactHtmlWithTag) {
        return { __html: theExactHtmlWithTag };
    }

    return (
        <section>
            {
                loadding ?
                    <>
                        <div className="text-center my-[150px]">
                            <h2 className='text-[25px]  font-medium text-slate-800 capitalize mb-5 text-center'>Data loadding please wait...</h2>
                            <img src={logo} alt="logo" className='text-center mx-auto h-[100px]' />
                        </div>
                    </>
                    :
                    <div className='my-32'>
                        <div className="container mx-auto px-5 md:px-12">
                            {/* <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-5 gap-y-8"> */}
                            <div className="flex lg:flex-row flex-col gap-5 ">
                                <div className="post-details lg:basis-3/4 basis-full">
                                    <Zoom>
                                        <div className="post-admin flex gap-4 mb-8">
                                            <div className="admin-logo">
                                                <img src={project?.photo} alt="admin" className='w-[50px] h-[50px] rounded-full' />
                                            </div>
                                            <div className="admin-title">
                                                <h2 className='text-[17px] text-gray-700 capitalize flex items-center gap-2'><AiOutlineUserAdd />{project?.name}</h2>
                                                <p className='text-[15px] text-gray-500 flex items-center gap-2'><FaClock />{project?.date}</p>
                                            </div>
                                        </div>
                                        <div className="project-details">
                                            <h2 className='text-[25px]  font-semibold text-slate-700 capitalize mb-5'>{project?.title}</h2>
                                            <img src={project?.thubnail} alt="photo" className='object-center md:h-[400px] h-auto' />
                                            <h2 className='text-slate-600 text-[18px] font-semibold mt-5' dangerouslySetInnerHTML={createMarkup(`${project?.disc}`)}>
                                            </h2>
                                        </div>
                                    </Zoom>
                                </div>
                                <div className="recnt-project lg:basis-3/12 basis-full">
                                    <RecentProject />
                                </div>
                            </div>
                        </div >
                    </div >
            }
        </section >
    );
};

export default ProjectDetails;