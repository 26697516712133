import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { BsArrowRightCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom'

const RecentProject = () => {
    const [project, setProject] = useState([]);


    useEffect(() => {
        fetch(`${backendApi}/project/getAllNotice`)
            .then(res => res.json())
            .then(data => setProject(data))

    }, [project])

    return (
        <div>
            <h2 className='text-[23px]  font-medium mt-5 text-blue-500 capitalize underline'>Recents Projects</h2>
            <div className="sho-project">
                {
                    project?.slice(0, 10).map((item, index) => {
                        const { title, thubnail, _id } = item;
                        return (
                            <Zoom>
                                <Link to={`/project-details/${_id}`} className='cursor-pointer'>
                                    <div className="shadow-md shadow-gray-300 my-5 rounded-xl h-auto overflow-hidden" key={index}>
                                        <div className="flex gap-4">
                                            <div className="logo-box">
                                                <img src={thubnail} alt="oikko" className='hover:grayscale rounded-xl h-[100px] w-[100px] object-fill mx-auto' />
                                            </div>
                                            <div className="project-content">
                                                <h2 className='text-[18px]  font-semibold mt-3 text-slate-600 capitalize'>{title}</h2>
                                                <button className='flex items-center gap-2  bg-gradient-to-r my-3 text-[15px] font-medium text-white from-blue-600 to-blue-500 hover:from-orange-500 hover:to-orange-400 rounded-lg p-1'>Read More <BsArrowRightCircle /></button>
                                            </div>
                                        </div>

                                    </div>
                                </Link>

                            </Zoom>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default RecentProject;