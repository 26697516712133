import React from 'react';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import DashBoardLoadding from '../DashBoardLoadding/DashBoardLoadding';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';

const Message = () => {
    const [message, setMessage] = useState([])


    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/message/getAllMessage`)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setMessage(data)
            })
    }, [message])




    //Remove Single message
    const handleRemoveMessage = (id) => {
        const user = window.confirm('Do you want delete this Message?');

        if (user) {
            fetch(`${backendApi}/message/removeMessage/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }




    return (
        <section className='all-message-section overflow-hidden'>
            {
                message?.length === 0 ?
                    <>
                        <h2 className=' text-blue-500 font-semibold text-[30px] mt-[130px] mb-2 capitalize'>Volunteer proposal message : {message?.length}</h2>
                        {/* <DashBoardLoadding /> */}
                    </>
                    :

                    <div className='my-28 px-12'>
                        <h1 className=' text-[22px] font-bold text-slate-800  mb-[10px] text-center capitalize'> volunteer  <span className='text-blue-500'>proposal </span> Message</h1>

                        <div className="shadow-md  w-1/3 md:w-full mt-14">
                            <table className="w-[30%] overflow-y-hidden">
                                <thead className="bg-slate-300">
                                    <tr className='text-center rounded-xl'>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name & Email</th>
                                        <th className="px-12 py-4 text-[17px] text-slate-800 font-[600]">Address</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Mobile</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Gender</th>
                                        <th className="px-16 py-4 text-[17px] text-slate-800 font-[600]">Date</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        message?.map((items, index) => {
                                            const { userName, email, _id, phone, address, division, gender, time } = items;
                                            return (
                                                <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize text-slate-500">{index + 1}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] text-slate-500 capitalize">
                                                        <p>{userName}</p>
                                                        <p>{email}</p>
                                                    </td>
                                                    <td className="px-2 py-4 text-center text-[16px] text-slate-500">
                                                        <p className='capitalize'> {address}</p>
                                                        <p> {division}</p>
                                                    </td>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize text-slate-500">0{phone}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize text-slate-500">{gender}</td>
                                                    <td className="px-2 py-4 text-center text-[16px] capitalize text-slate-500">{time}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize text-slate-500">
                                                        <FiTrash2 className='text-red-400 cursor-pointer text-center mx-auto' onClick={() => handleRemoveMessage(_id)} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </section >
    );
};

export default Message;