import React, { useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi'
import { BsSearch } from 'react-icons/bs'
import { toast } from 'react-toastify';
import DashBoardLoadding from '../DashBoardLoadding/DashBoardLoadding';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import EditVolunteer from './EditVolunteer';
import VolunteerModal from './VolunteerModal';


const AddVolunteer = () => {
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')


    //load user api 
    useEffect(() => {

        fetch(`${backendApi}/team/getAllTeam`)
            .then(res => res.json())
            .then(data => {
                setUsers(data);

            })
    }, [users])


    //Remove Single Users
    const handleRemoveContact = (id) => {
        const user = window.confirm('Do you want delete this Member?');

        if (user) {
            fetch(`${backendApi}/team/removeTeam/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }





    return (
        <section className='all-users-section overflow-hidden'>
            {
                users?.length === 0 ?
                    <>
                        <h2 className='text-center text-gray-800 font-semibold text-[20px] mt-[130px] mb-2'>Data Loadding please wait......</h2>
                        <DashBoardLoadding />
                    </>
                    :

                    <div className='my-28 px-12'>
                        <h1 className=' text-[22px] font-semibold text-slate-800  mb-[10px] text-center'> All <span className='text-blue-500'>Volunteer</span> List</h1>
                        {/* Search Box  */}
                        <div className="seach-add flex items-center justify-between bg-slate-800 rounded-lg">
                            <div className="search-box relative flex items-center m-5">
                                <input type="text" placeholder='Search Name' className='rounded-lg p-2 block  bg-slate-700 relative focus:ring-2 focus:ring-blue-500 placeholder-gray-400 w-full text-white' onChange={(e) => setSearch(e.target.value)} />
                                <BsSearch className='absolute left-[160px] text-gray-400' />
                            </div>
                            <VolunteerModal />
                        </div>

                        <div className="shadow-md  w-1/3 md:w-full mt-14">
                            <table className="w-full">
                                <thead className="bg-slate-300">
                                    <tr className='text-center rounded-xl'>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Profile</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Position</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Gender</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Action</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Edit</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users?.filter(item => {
                                            return item?.name?.toLowerCase().includes(search)
                                        }).map((user, index) => {
                                            const { name, title, gender, _id, photo } = user;
                                            return (
                                                <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize font-semibold text-slate-500">{index + 1}</td>
                                                    <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                                        <div className="relative h-10 w-10">
                                                            <img
                                                                className="h-full w-full rounded-full object-cover object-center"
                                                                src={photo}
                                                                alt="profile"
                                                            />
                                                            <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                                        </div>

                                                    </td>
                                                    <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500 capitalize">{name}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] font-semibold capitalize text-slate-500">{title}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] font-semibold capitalize text-slate-500">{gender}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] font-semibold capitalize text-slate-500">
                                                        <FiTrash2 className='text-red-400 cursor-pointer text-center mx-auto' onClick={() => handleRemoveContact(_id)} />
                                                    </td>
                                                    <td className="px-6 py-4 text-center text-[16px] ">
                                                        <EditVolunteer id={_id} className='text-slate-800' />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </section >
    );
};

export default AddVolunteer;