import React from 'react';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'
import { useState } from 'react';
import { useEffect } from 'react';
import { BsArrowDownUp } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { toast } from 'react-toastify';
import FileUpload from './FileUpload';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import DashBoardLoadding from '../DashBoardLoadding/DashBoardLoadding';





const DashGallery = () => {
    const [gallery, setGallery] = useState([]);
    const [showPhoto, setShowPhoto] = useState(false);


    // load Photo API 
    useEffect(() => {
        fetch(`${backendApi}/gallery/getAllGallPhoto`)
            .then(res => res.json())
            .then(data => {
                setGallery(data);
                // console.log(data);
            })
    }, [gallery])

    //Handle Show More 
    const handleShowMore = () => {
        setShowPhoto(!showPhoto)
    }


    //Handle Remove Photo 
    const handleRemovePhoto = (id) => {
        const user = window.confirm('Do you want delete this photo?');

        if (user) {
            fetch(`${backendApi}/gallery/removeGallPhoto/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    return (
        <section className='gallrey-section overflow-hidden'>
            {
                gallery?.length === 0 ?
                    <>
                        <h2 className=' text-blue-500 font-semibold text-[30px] mt-[130px] mb-2 capitalize'>Total Gallery: {gallery?.length}</h2>
                        <FileUpload />
                    </>
                    :

                    <div className="container mx-auto py-[100px] px-[20px] mt-[50px]">
                        <div className="feedback-title text-center mb-[10px]">
                            <RubberBand>
                                <h1 className='text-slate-800 text-[25px] font-bold text-center'> Our  <span className='text-blue-500'>Gallery</span> Photo</h1>
                            </RubberBand>
                        </div>
                        {/* =============File Upload Componets============= */}
                        <FileUpload />
                        {/* =============File Upload Componets============= */}
                        <div className="gallarey-main">
                            <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-4 gap-x-5 gap-y-8">
                                <React.Fragment>
                                    {
                                        showPhoto ?

                                            gallery?.map((galPhoto, index) => {
                                                const { photo, _id } = galPhoto;

                                                return (

                                                    <PhotoProvider
                                                        loop={true}
                                                        speed={() => 800}
                                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}

                                                    >
                                                        <div className="rounded-2xl">
                                                            <Zoom>
                                                                <PhotoView key={index} src={photo}>
                                                                    <img src={photo} alt="logo" className='h-[150px]  w-[400px] cursor-pointer mx-auto rounded-2xl transition ease-in-out duration-75 hover:grayscale' />
                                                                </PhotoView>
                                                                <button className="flex items-center bg-gradient-to-r from-red-400 to-pink-500 rounded-lg gap-x-2 p-2 text-white justify-center text-[12px] mx-auto my-2" onClick={() => handleRemovePhoto(_id)}> <FaRegTrashAlt />Remove Photo</button>
                                                            </Zoom>
                                                        </div>
                                                    </PhotoProvider>
                                                )
                                            }) :
                                            gallery?.slice(0, 8).map((galPhoto, index) => {
                                                const { photo, _id } = galPhoto;
                                                return (
                                                    <PhotoProvider
                                                        loop={true}
                                                        speed={() => 800}
                                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}

                                                    >
                                                        <div className="rounded-2xl">
                                                            <Zoom>
                                                                <PhotoView key={index} src={photo}>
                                                                    <img src={photo} alt="logo" className='h-[150px]  w-[400px] cursor-pointer mx-auto rounded-2xl transition ease-in-out duration-75 hover:grayscale' />
                                                                </PhotoView>
                                                                <button className="flex items-center bg-gradient-to-r from-red-400 to-pink-500 rounded-lg gap-x-2 p-2 text-white justify-center text-[12px] mx-auto my-2" onClick={() => handleRemovePhoto(_id)}> <FaRegTrashAlt />Remove Photo</button>
                                                            </Zoom>
                                                        </div>
                                                    </PhotoProvider>
                                                )
                                            })
                                    }
                                </React.Fragment>
                            </div>
                            <div className="more-btn text-center my-[25px]">
                                {
                                    showPhoto ?
                                        <>
                                            <button className='bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-full p-2 flex items-center gap-2 justify-center mx-auto  font-semibold' onClick={() => handleShowMore(!showPhoto)}>No Photo go Top  <BsArrowDownUp /></button>
                                        </>
                                        :
                                        <>
                                            <button className='bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-full p-2 flex items-center gap-2 justify-center mx-auto font-semibold' onClick={() => handleShowMore(!showPhoto)}>See More <BsArrowDownUp /></button>
                                        </>
                                }
                            </div>

                        </div >
                    </div >
            }
        </section >
    );
};

export default DashGallery;