import React from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';

import logo2 from '../../../../images/header/22.jpg'
import logo3 from '../../../../images/header/33.jpg'

const Organization = () => {

    return (
        <div className='mb-32 mt-24 md:my-36'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-32 gap-y-10">
                    <Slide left>
                        <div className="organize-title relative cursor-pointer">
                            <img src={logo2} alt="oikko" className='rounded-xl hover:grayscale relative z-10' />
                            <img src={logo3} alt="oikko" className='rounded-xl hover:grayscale hidden lg:block h-[200px] z-50 absolute -right-[70px] -bottom-[8px]' />
                        </div>
                    </Slide>
                    <Slide right>
                        <div className="organize-title">
                            <h2 className='text-slate-800 font-bold  text-[28px]'><span className='text-blue-500'>OIKKO</span> - A Non-profit Organization</h2>
                            <p className='text-gray-500 my-5 font-semibold  text-[18px]'>
                                Oikko is a non-Profit welfare Organization. We have started our journy from 2015. We helped more than 3000 people in whole Bangladesh. We helped poor people in every Eid, Winter Season and Flood-Victims We have more than 100 team members. All them so supportive and amazing. We are always here to help you.
                            </p>
                            <p className='text-gray-500 my-5 font-semibold  text-[18px]'>
                                Provide support to underprivileged section of the society specially extreme poor, homeless and orphan children through quality education, nourishment & shelter to make them resources for the nation.
                            </p>
                            <Link to='/about'>
                                <button className='flex items-center gap-2 bg-gradient-to-r  text-[16px] my-2 font-medium text-white  from-blue-500 to-blue-600 hover:from-orange-400 hover:to-orange-500 rounded-xl p-2'>Read More <BsArrowRightCircle /></button>
                            </Link>
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default Organization;