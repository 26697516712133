import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaMapMarker, FaPhone, FaTwitter, FaHeart } from 'react-icons/fa'
import logo from '../../../images/logo/oikko.png'
import payment from '../../../images/payment/payment.png'
import './Footer.css'

const Footer = () => {
    return (
        <footer className='footer bg-slate-800  pt-12 pb-12'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="footer-main grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-x-5 gap-y-12">
                    <div className="footer-item">
                        <Link to='/'>
                            <img src={logo} alt="Oikko" className='h-[100px] mb-2' />
                        </Link>
                        <p className='text-gray-400  font-medium'>Oikko is a non-Profit welfare Organization. We have started our journy from 2015. We helped more than 3000 people in whole Bangladesh. We helped poor people in every Eid, Winter Season and Flood-Victims</p>
                        <div className="footer-social my-5">
                            <ul className='flex items-center flex-wrap gap-5'>
                                <li>
                                    <a href="http://www.facebook.com/groups/oikko.awo" target="_blank" rel="noopener noreferrer" className='w-[80] h-[80] rounded-full p-2 bg-slate-700 hover:bg-rose-500 transition ease-in-out duration-75 flex items-center text-white'>
                                        <FaFacebookF />
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.facebook.com/oikko.awo" target="_blank" rel="noopener noreferrer" className='w-[80] h-[80] rounded-full p-2 bg-slate-700 hover:bg-rose-500 transition ease-in-out duration-75 flex items-center text-white'>
                                        <FaLinkedinIn />
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.facebook.com/groups/oikko.awo" target="_blank" rel="noopener noreferrer" className='w-[80] h-[80] rounded-full p-2 bg-slate-700 hover:bg-rose-500 transition ease-in-out duration-75 flex items-center text-white'>
                                        <FaTwitter />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-item">
                        <h2 className='text-gray-300 font-semibold text-[18px] capitalize mb-1 '>Important Links</h2>
                        <div className="line bg-rose-500 rounded-full h-[2px] w-[80px] mb-5"></div>
                        <div className="footer-sub-menu">
                            <ul className='capitalize'>
                                <li>
                                    <Link to='/'>Home</Link>
                                </li>
                                <li>
                                    <Link to='/about'>About us</Link>
                                </li>
                                <li>
                                    <Link to='/donate'>donate</Link>
                                </li>
                                <li>
                                    <Link to='/gallery'>Gallery</Link>
                                </li>
                                <li>
                                    <Link to='/projects'>Projects</Link>
                                </li>
                                <li>
                                    <Link to='/volunteer'>Volunteer</Link>
                                </li>
                                <li>
                                    <Link to='/contact'>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-item">
                        <h2 className='text-gray-300 font-semibold text-[18px] capitalize mb-1 '>Contact with us</h2>
                        <div className="line bg-rose-500 rounded-full h-[2px] w-[80px] mb-5"></div>
                        <div className="addresss-fot">
                            <h5 className='flex items-center text-gray-400 font-medium'><FaMapMarker className='mr-5' />Tetulia, Panchagarh, Bangladesh</h5>
                            <h5 className='flex items-center text-gray-400 font-medium my-5'><FaPhone className='mr-5' />	+880 1738 733 745</h5>
                            <h5 className='flex items-center text-gray-400 font-medium'><FaEnvelope className='mr-5' />oikko.awo@gmail.com</h5>
                        </div>

                    </div>
                    <div className="footer-item">
                        <h2 className='text-gray-300 font-semibold text-[18px] capitalize mb-1 '>Payments Methods</h2>
                        <div className="line bg-rose-500 rounded-full h-[2px] w-[80px] mb-5"></div>
                        <img src={payment} alt="payment" className='rounded-xl' />
                    </div>
                </div>

                <div className="copyright mt-12">
                    <h5 className='text-gray-400 text-center font-medium flex items-center flex-wrap justify-center'>{new Date().getFullYear()} © Oikko Organization | Developed  By  <a href="https://www.facebook.com/bakulray.ajoy" target="_blank" className='text-orange-400 ml-2 flex items-center gap-1'> <FaHeart className='text-orange-400 flex items-center mr-1' /> Bakul Ray</a></h5>
                </div>
            </div>
        </footer>
    );
};

export default Footer;