import React, { useEffect, useState } from 'react';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'
import { BsArrowDownUp } from 'react-icons/bs';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import GalleryLoadding from '../../Animation/GalleryLoadding';




const Gallery = () => {
    const [gallery, setGallery] = useState([])
    const [loadding, setLoadding] = useState(false)
    const [showMore, setShowMore] = useState(false)







    //load gallery data 
    useEffect(() => {
        fetch(`${backendApi}/gallery/getAllGallPhoto`)
            .then(res => res.json())
            .then(data => {
                setGallery(data);
                setLoadding(false)
            })
            .catch(err => {
                setLoadding(true)
            })
    }, [gallery])



    return (
        <div className='my-32 overflow-hidden'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="gallery-title mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]  font-extrabold text-slate-800 uppercase'>our   <span className='text-blue-500'>gallery</span> Photo</h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>See our gallery photo</h2>
                    </RubberBand>
                </div>
                {
                    gallery?.length === 0 || loadding ?
                        <>
                            <GalleryLoadding />
                            <div className='mt-5'></div>
                            <GalleryLoadding />
                        </>
                        :

                        <div className="show-gallrey">
                            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
                                {
                                    showMore ?
                                        gallery?.map((item, index) => {
                                            const { photo } = item;
                                            return (
                                                <Zoom>
                                                    <PhotoProvider
                                                        speed={() => 400}
                                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}

                                                    >
                                                        <PhotoView key={index} src={photo}>
                                                            <img src={photo} alt="logo" className='h-[250px] w-full object-center cursor-pointer mx-auto rounded-2xl transition ease-in-out duration-75 hover:grayscale' />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                </Zoom>
                                            )
                                        })
                                        :
                                        gallery?.slice(0, 12).map((item, index) => {
                                            const { photo } = item;
                                            return (
                                                <Zoom>
                                                    <PhotoProvider
                                                        speed={() => 400}
                                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}

                                                    >
                                                        <PhotoView key={index} src={photo}>
                                                            <img src={photo} alt="logo" className='h-[250px] w-full object-center cursor-pointer mx-auto rounded-2xl transition ease-in-out duration-75 hover:grayscale' />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                </Zoom>
                                            )
                                        })
                                }
                            </div>

                            <div className="show-btn">
                                {
                                    showMore ?
                                        <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] mx-auto my-4 font-semibold text-white  from-[#3A5896] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' onClick={() => setShowMore(!showMore)}>No Photos Go Up<BsArrowDownUp /></button>
                                        :
                                        <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] mx-auto my-4 font-semibold text-white  from-[#3A5896] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' onClick={() => setShowMore(!showMore)}>See More Photos<BsArrowDownUp /></button>
                                }
                            </div>

                        </div>
                }
            </div >
        </div >
    );
};

export default Gallery;