import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TeamLoadding = () => {
    return (
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-5 gap-x-5 gap-y-8">
            <div>
                <Skeleton
                    count={1}
                    baseColor="#5f6f89"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#5f6f89"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#5f6f89"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#5f6f89"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#5f6f89"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
        </div>
    );
};

export default TeamLoadding;