import React, { useEffect, useState } from 'react';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';


const DashHomeUser = () => {
    const [users, setUsers] = useState([])
    const [loadding, setLoadding] = useState(false)

    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/auth/allusers`)
            .then(res => res.json())
            .then(data => {
                setUsers(data?.slice(0, 3))
                setLoadding(false)
            })
            .catch(err => {
                setLoadding(true)
            })
    }, [users])






    return (
        <section className='all-users-section'>
            {
                users?.length === 0 || loadding ?
                    <>
                        <h2 className='text-center text-slate-800 font-semibold text-[20px] my-24 capitalize'>Data loadding please wait...</h2>
                    </>
                    :

                    <div className='my-10'>
                        <h1 className=' text-[20px] font-semibold text-slate-800  mb-[10px]'>Admin <span className='text-blue-500'>access</span> users</h1>

                        <div className="shadow-md  w-1/3 md:w-full">
                            <table className="w-full">
                                <thead className="bg-slate-300">
                                    <tr className='text-center rounded-xl'>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial No.</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Photo</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Role</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((user, index) => {
                                            const { userName, profile, role, email } = user;
                                            return (
                                                <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                    <td className="px-6 py-4 text-center font-semibold text-slate-500 text-[20px]">{index + 1}</td>
                                                    <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                                        <div className="relative h-10 w-10">
                                                            <img
                                                                className="h-full w-full rounded-full object-cover object-center"
                                                                src={profile}
                                                                alt="profile"
                                                            />
                                                            <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                                        </div>
                                                        <div className="text-sm">
                                                            <div className="font-semibold text-gray-500 capitalize">{userName}</div>
                                                            <div className="text-gray-500 font-semibold">{email}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-center text-[16px] font-semibold capitalize text-slate-500">{userName}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] text-slate-500 capitalize font-semibold">{role}</td>
                                                    <td className="px-6 py-4 text-center">
                                                        <span
                                                            className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                                        >
                                                            <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                                            Active
                                                        </span>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </section >
    );
};

export default DashHomeUser;