import React from 'react';
import Slide from 'react-reveal/Slide';
import RubberBand from 'react-reveal/RubberBand';
import Volunteer from '../Home/Volunteer/Volunteer'
import { FaCheckCircle } from 'react-icons/fa'
import './NeedVolunteer.css'



const NeedVolunteer = () => {
    return (
        <div className='volunteer-sec overflow-hidden mt-32 mb-5'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="team-title mb-16">
                    <RubberBand>
                        <h2 className='text-center text-[28px]   font-extrabold text-slate-950 uppercase'>Join <span className='text-blue-500'>Volunteer</span> </h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>You can join as volunteer </h2>
                    </RubberBand>
                </div>
                <div className="volunteer-roles mb-24">
                    <Slide right>
                        <h2 className='text-[25px]  font-semibold text-blue-800 capitalize'>সদস্য হওয়ার শর্তাবলি :</h2>
                        <ul className='condition-roles'>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />সংগঠনের সদস্য হওয়ার ক্ষেত্রে প্রতি মাসে নুন্যতম ১০০/- মাসিক অফেরতযোগ্য ডোনেট
                                করতে হবে।
                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />জয়েন করার সময় ২০০/- টাকা অবশ্যই ডোনেট করতে হবে ।
                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />সংগঠনের ভাবমূর্তি ক্ষুণ্ন হয় এমন কোন কাজ করা যাবে না।
                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />সংগঠনের সকল কার্যক্রমে স্বতঃস্ফূর্ত অংশ গ্রহণ নিশ্চিত করতে হবে।
                                করতে হবে।
                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />
                                রাজনৈতিক কোন কার্যকলাপ সংগঠনের সাথে যুক্ত করা যাবে না।
                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />
                                একার সিদ্ধান্তে সংগঠনের কোন কর্মকাণ্ড সম্পাদন করা যাবে না।

                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />
                                কোন সদস্য যদি তার সদস্যপদ বাতিল করতে চায় তাহলে কতৃপক্ষকে অবহিত করে সদস্যপদ বাতিল করতে পারবে।

                            </li>
                            <li className='flex items-center gap-2 text-gray-700 text-[18px] font-semibold'><FaCheckCircle />
                                উপরোক্ত শর্তাবলী লংঘন করলে সদস্যপদ সরাসরি বাতিল বলে গণ্য হবে।

                            </li>
                        </ul>
                    </Slide>
                </div>
            </div>
            {/* ============Home page Volunteer=========== */}
            <Volunteer />

        </div>
    );
};

export default NeedVolunteer;