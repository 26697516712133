import React from 'react';
import logo from '../../../images/404.png'
import { Link } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai'
import Zoom from 'react-reveal/Zoom';

const NoMatch = () => {
    return (
        <section className='error-section overflow-hidden'>
            <div className='container mx-auto px-5'>
                <Zoom>
                    <div className="title-error  mt-[80px]">
                        <img src={logo} alt="logo" className='mx-auto h-[300px]' />
                        <h2 className='text-white text-[20px] font-medium text-center '>We couldn't find this page, Please go to home page</h2>

                        <div className='text-center mt-4 mb-20'>
                            <Link to='/' className='rounded-xl  p-2 text-center text-white font-medium justify-center bg-rose-500 flex items-center gap-x-2 w-[200px]  mx-auto'><AiOutlineHome />Home Page</Link>
                        </div>

                    </div>
                </Zoom>
            </div>
        </section>
    );
};

export default NoMatch;