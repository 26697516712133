import React, { useEffect, useState } from 'react';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'
import { BsArrowRightCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import GalleryLoadding from '../../Animation/GalleryLoadding';


const AllProject = () => {
    const [project, setProject] = useState([]);
    const [show, setShow] = useState(false);


    useEffect(() => {
        fetch(`${backendApi}/project/getAllNotice`)
            .then(res => res.json())
            .then(data => setProject(data))

    }, [project])



    return (
        <div className='overflow-hidden my-32'>
            <div className="container mx-auto px-5 md:px-10">
                <div className="project-title mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]   font-extrabold text-slate-950 uppercase'>our   <span className='text-blue-500'>Projects</span></h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>What we do for our society</h2>
                    </RubberBand>
                </div>
                {
                    project?.length === 0 ?
                        <>
                            <GalleryLoadding />
                            <div className="div my-5"></div>
                            <GalleryLoadding />
                        </>
                        :

                        <div className="home-project">
                            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
                                {
                                    show ?
                                        project?.map((item, index) => {
                                            const { title, thubnail, name, photo, date, _id } = item;
                                            return (
                                                <Zoom>
                                                    <Link to={`/project-details/${_id}`} className='cursor-pointer'>
                                                        <div className="shadow-lg shadow-gray-500 my-2 border border-transparent hover:border hover:border-blue-400 rounded-xl h-[430px] overflow-hidden" key={index}>
                                                            <img src={thubnail} alt="oikko" className='hover:grayscale rounded-t-xl h-[200px] w-full mx-auto relative' />
                                                            <div className="project-content px-4 pb-5">
                                                                <h2 className='text-[20px]  font-semibold mt-3 text-slate-950 capitalize'>{title}</h2>
                                                                <div className="flex items-center gap-2 my-4 ">
                                                                    <img src={photo} alt="oikko" className='rounded-full h-[30px] w-[30px]' />
                                                                    <div className="title-sec">
                                                                        <p className='text-[17px]  font-medium text-slate-800 capitalize'>{name}</p>
                                                                        <p className='text-[15px]  font-medium text-slate-700'>{date}</p>
                                                                    </div>

                                                                </div>


                                                                <button className='flex items-center gap-2  bg-gradient-to-r my-3 text-[15px] font-medium text-white from-blue-600 to-blue-500 hover:from-orange-500 hover:to-orange-400 rounded-lg p-1'>Read More <BsArrowRightCircle /></button>

                                                            </div>
                                                        </div>
                                                    </Link>

                                                </Zoom>
                                            )
                                        })
                                        :
                                        project?.slice(0, 8).map((item, index) => {
                                            const { title, thubnail, name, photo, date, _id } = item;
                                            return (
                                                <Zoom>
                                                    <Link to={`/project-details/${_id}`} className='cursor-pointer'>
                                                        <div className="shadow-lg shadow-gray-500 my-2 rounded-xl h-[430px] overflow-hidden" key={index}>
                                                            <img src={thubnail} alt="oikko" className='hover:grayscale rounded-t-xl h-[200px] w-full mx-auto relative' />
                                                            <div className="project-content px-4 pb-5">
                                                                <h2 className='text-[20px]  font-semibold mt-3 text-slate-950 capitalize'>{title}</h2>
                                                                <div className="flex items-center gap-2 my-4 ">
                                                                    <img src={photo} alt="oikko" className='rounded-full h-[30px] w-[30px]' />
                                                                    <div className="title-sec">
                                                                        <p className='text-[17px]  font-medium text-slate-800 capitalize'>{name}</p>
                                                                        <p className='text-[15px]  font-medium text-slate-700'>{date}</p>
                                                                    </div>

                                                                </div>


                                                                <button className='flex items-center gap-2  bg-gradient-to-r my-3 text-[15px] font-medium text-white from-blue-600 to-blue-500 hover:from-orange-500 hover:to-orange-400 rounded-lg p-1'>Read More <BsArrowRightCircle /></button>

                                                            </div>
                                                        </div>
                                                    </Link>

                                                </Zoom>
                                            )
                                        })
                                }
                            </div>

                        </div>
                }
            </div>

            <div className="showMore my-16">
                {
                    project?.length === 0 ? '' :

                        <>
                            {
                                show ?

                                    < button className='flex items-center mt-[20px] md:mt-0 gap-2  bg-gradient-to-r text-[16px] font-medium text-white from-[#3A5896] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2 mx-auto justify-center' onClick={() => setShow(!show)}>Please go up <BsArrowRightCircle /></button>
                                    :

                                    <button className='flex items-center mt-[20px] md:mt-0 gap-2  bg-gradient-to-r text-[16px] font-medium text-white from-[#3A5896] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2 mx-auto justify-center' onClick={() => setShow(!show)}>See all Projects <BsArrowRightCircle /></button>

                            }
                        </>
                }
            </div>
        </div >
    );
};

export default AllProject;