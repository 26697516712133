import React, { useState, useEffect } from 'react';
import { MdCloudUpload } from 'react-icons/md'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const FileUpload = () => {
    const [image, setImage] = useState('');
    const [preview, setPreview] = useState()
    const [loadding, setLoadding] = useState(false)

    const imageBBAPi = 'a8debf6b1d0f4ee3bab28f8c0d4b9937'
    const navigate = useNavigate();

    //Modal open and close 
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const handleUpload = (e) => {
        setImage(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append("image", image)


        // get all data form api 
        setLoadding(true)
        fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    const imageUrl = result?.data?.url;

                    const sendData = {
                        photo: imageUrl
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendApi}/gallery/gallUpload`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/gallery')
                                setLoadding(false);
                                setImage('')
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)

                                }
                            }

                        })
                }
            })
    }


    return (
        <div className='file-upload my-5 text-center p-5'>
            <button className='bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg p-2 flex items-center justify-center gap-2' onClick={onOpenModal}><MdCloudUpload className='inline text-2xl' /> <span>Upload Photo</span></button>
            <Modal open={open} onClose={onCloseModal} center animationDuration='100' classNames={{
                modal: 'customModal2',
            }}>
                <h2 className='text-center my-5 text-slate-800 font-semibold text-[25px]'>Upload Photo</h2>
                {
                    image ? <img src={preview} alt="review " className='w-full rounded-lg h-[200px] object-center' /> : ''
                }

                <form onSubmit={handleSubmit} className='text-center'>
                    {
                        image ? '' : <label htmlFor="fff" className='cursor-pointer'><MdCloudUpload className='inline text-[50px]' /> </label>
                    }

                    <input type="file" onChange={handleUpload} className='hidden' id='fff' />
                    <br />
                    {
                        loadding ?
                            <button className='rounded-xl bg-rose-500 p-2 text-white w-full text-center justify-center last:font-semibold capitalize flex items-center gap-2' disabled>Photo uploading please wait...<span class="loader"></span> </button>
                            :
                            <button className='flex items-center justify-center mx-auto gap-2 bg-gradient-to-r  text-[16px] my-2 font-medium text-white  from-blue-500 to-blue-600 hover:from-orange-400 hover:to-orange-500 rounded-xl p-2'>Upload Save <BsArrowRightCircle /></button>
                    }

                </form>

            </Modal>

        </div>
    );
};

export default FileUpload;