import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GalleryLoadding = () => {
    return (
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
            <div>
                <Skeleton
                    count={1}
                    baseColor="#93a2b9"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#93a2b9"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#93a2b9"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
            <div>
                <Skeleton
                    count={1}
                    baseColor="#93a2b9"
                    width='100%'
                    height={200}
                    highlightColor="#0F172A"
                    borderRadius='10px'
                />
            </div>
        </div>
    );
};

export default GalleryLoadding;