import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, redirect } from 'react-router-dom';
import Home from './Componets/Pages/Home/Home';
import NavBar from './Componets/Shared/NavBar/NavBar';
import Footer from './Componets/Shared/Footer/Footer';
import NoMatch from './Componets/Shared/NoMatch/NoMatch';
import ScrollRebot from './Componets/Shared/ScrollRebot/ScrollRebot';
import ScrollToTop from './Componets/Shared/ScrollToTop/ScrollToTop';
import Contact from './Componets/Pages/Contact/Contact';
import TeamMember from './Componets/Pages/TeamMember/TeamMember';
import NeedVolunteer from './Componets/Pages/NeedVolunteer/NeedVolunteer';
import Donation from './Componets/Pages/Donation/Donation';
import About from './Componets/Pages/About/About';
import AllProject from './Componets/Pages/AllProject/AllProject';
import ProjectDetails from './Componets/Pages/AllProject/ProjectDetails';
import Gallery from './Componets/Pages/Gallery/Gallery';
import Login from './Componets/Authentication/Login/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './Componets/Authentication/Register/Register';
import FindAccount from './Componets/Authentication/ForgetPassword/FindAccount';
import ForgetPassword from './Componets/Authentication/ForgetPassword/ForgetPassword';
import DashBoard from './Componets/DashBoard/DashBoard';
import DashHome from './Componets/DashBoard/DashHome/DashHome';
import Profile from './Componets/Authentication/Profile/Profile';
import Admin from './Componets/DashBoard/Admin/Admin';
import AllUsers from './Componets/DashBoard/AllUsers/AllUsers';
import DashGallery from './Componets/DashBoard/DashGallery/DashGallery';
import Message from './Componets/DashBoard/Message/Message';
import AddVolunteer from './Componets/DashBoard/AddVolunteer/AddVolunteer';
import AddProject from './Componets/DashBoard/AddProject/AddProject';
import SpecialMessage from './Componets/DashBoard/SpecialMessage/SpecialMessage';
import ManagmentProject from './Componets/DashBoard/ManagmentProject/ManagmentProject';
import PreLoader from './Componets/PreLoader/PreLoader';





const App = () => {
  const [loadding, setLoadding] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoadding(false)
    }, 1000);
  })


  const getToken = localStorage.getItem('token');


  const removeFunToken = () => {
    if (!getToken) {
      return setTimeout(() => {
        redirect('/login')
        localStorage.removeItem('token');
      }, [1000])
    }
  }

  return (
    <section>
      {
        loadding ?
          <>
            <PreLoader />
          </>
          :

          <BrowserRouter>
            <ScrollRebot />
            <ScrollToTop />
            <NavBar />

            <Routes>
              {/* ====================== All Pages ======================== */}
              <Route path='/' element={<Home />}></Route>
              <Route path='/team-members' element={<TeamMember />}></Route>
              <Route path='/donate' element={<Donation />}></Route>
              <Route path='/contact' element={<Contact />}></Route>
              <Route path='/volunteer' element={<NeedVolunteer />}></Route>
              <Route path='/about' element={<About />}></Route>
              <Route path='/projects' element={<AllProject />}></Route>
              <Route path='/gallery' element={<Gallery />}></Route>
              <Route path='/profile' element={<Profile />}></Route>
              <Route path='/project-details/:id' element={<ProjectDetails />}></Route>
              {/* ====================== All Pages ======================== */}

              {/* ====================== Authentication ======================== */}
              <Route path='/login' element={<Login />}></Route>
              <Route path='/register' element={<Register />}></Route>
              <Route path='/find-account' element={<FindAccount />}></Route>
              <Route path='/new-password' element={<ForgetPassword />}></Route>
              {/* ====================== Authentication ======================== */}

              {/* ====================== Dashboard ======================== */}

              {
                getToken ?

                  <Route path='/dashboard' element={<DashBoard />}>
                    <Route index element={<DashHome />}></Route>
                    <Route path='/dashboard/all-users' element={<AllUsers />}></Route>
                    <Route path='/dashboard/upload-gallrey' element={<DashGallery />}></Route>
                    <Route path='/dashboard/message' element={<Message />}></Route>
                    <Route path='/dashboard/admin-access' element={<Admin />}></Route>
                    <Route path='/dashboard/add-volunteer' element={<AddVolunteer />}></Route>
                    <Route path='/dashboard/add-project' element={<AddProject />}></Route>
                    <Route path='/dashboard/special-message' element={<SpecialMessage />}></Route>

                    <Route path='/dashboard/project-management' element={<ManagmentProject />}></Route>

                  </Route>
                  :
                  removeFunToken()
              }

              {/* ====================== Dashboard ======================== */}

              {/* No Match  */}
              <Route path='*' element={<NoMatch />}></Route>

            </Routes>



            {/* Footer */}
            <Footer />
            <ToastContainer />

          </BrowserRouter >
      }
    </section>
  );
};

export default App;