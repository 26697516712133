import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BsSearch } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import DashBoardLoadding from '../DashBoardLoadding/DashBoardLoadding';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';

const Admin = () => {
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')



    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/auth/allusers`)
            .then(res => res.json())
            .then(data => setUsers(data))
    }, [users])

    //Remove Single Users
    const handleRemoveAdmin = (id) => {
        const user = window.confirm('Do you want delete this user?');

        if (user) {
            fetch(`${backendApi}/auth/removeSingleUser/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    // Make Admin 
    const handleMakeAdmin = (id) => {
        fetch(`${backendApi}/auth/makeAdmin/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(result => {
                console.log("object", result);
                if (result?.success) {
                    toast.success(result?.message)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }






    return (
        <section className='all-users-section overflow-hidden '>
            {
                users?.length === 0 ?
                    <>
                        <h2 className=' text-blue-500 font-semibold text-[30px] mt-[130px] mb-2 capitalize'>Total Admin: {users?.length}</h2>
                        {/* <DashBoardLoadding /> */}
                    </>
                    :

                    <div className='my-10'>
                        <h1 className=' text-[22px] font-bold text-slate-800  mb-[10px] text-center mt-24'> Admin  <span className='text-blue-500'>Access</span> List</h1>
                        {/* Search Box  */}
                        <div className="search-box relative flex items-center my-5  w-full md:w-[25%]">
                            <input type="text" placeholder='Search Name' className='rounded-lg p-2 w-full block  relative focus:ring-2 outline-0 border border-slate-400 focus:ring-blue-500 placeholder-gray-400  text-slate-800 font-semibold' onChange={(e) => setSearch(e.target.value)} />
                            <BsSearch className='absolute right-[10px] text-gray-500' />
                        </div>

                        <div className="shadow-md  w-1/3 md:w-full">
                            <table className="w-full">
                                <thead className="bg-slate-300">
                                    <tr className='text-center rounded-xl'>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Email</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Status</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Admin</th>
                                        <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users?.filter(item => {
                                            return item?.userName?.toLowerCase().includes(search)
                                        }).map((user, index) => {
                                            const { userName, email, _id, role } = user;
                                            return (
                                                <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                    <td className="px-6 py-4 text-center text-slate-500 text-[20px]">{index + 1}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] capitalize text-slate-500">{userName}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] text-slate-500">{email}</td>
                                                    <td className="px-6 py-4 text-center text-[16px] text-slate-500 capitalize">{role}</td>

                                                    <td className="px-6 py-4 text-center">
                                                        {
                                                            role === 'admin' ?
                                                                <h2 className='text-orange-500 font-semibold'>Admin Done</h2>
                                                                :
                                                                <button className='bg-blue-500 rounded-xl p-1 text-white' onClick={() => handleMakeAdmin(_id)}>Make Admin</button>
                                                        }

                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        <FiTrash2 className='text-red-400 cursor-pointer text-center text-[20px] mx-auto' onClick={() => handleRemoveAdmin(_id)} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </section >
    )
};

export default Admin;