import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useActiveUser from '../../../Hooks/useActiveUser';
import { BsArrowRightCircle } from 'react-icons/bs';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { useState } from 'react';


const ProfileRight = () => {
    const [loadding, setLoadding] = useState(false)
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [user] = useActiveUser();
    const id = user?._id;


    const onSubmit = data => {
        setLoadding(true)
        fetch(`${backendApi}/auth/updateProfile/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message);
                    reset();
                    setLoadding(false)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }

    return (
        <div className="login-form shadow-xl box-border rounded-xl p-4 mb-12 mx-auto shadow-gray-400">
            <h2 className=' text-blue-500 text-[20px] font-semibold mb-8'>Update Profile</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="profileright-details">
                    <div className="grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-4 gap-y-2 ">
                        <div className="profile-form">
                            <h3 className=' text-gray-800 font-semibold pb-1 text-[17px]'>Full Name</h3>
                            <input {...register("userName", { required: true })} type='text' placeholder="Name " className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.userName && <span className='text-red-500'>The fill is required</span>}
                        </div>
                        <div className="profile-form">
                            <h3 className=' text-gray-800  font-semibold pb-1 text-[17px]'>Address</h3>
                            <input {...register("address", { required: true })} type='text' placeholder="address" className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.address && <span className='text-red-500'>The fill is required</span>}
                        </div>
                    </div>
                    <div className="grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-4 gap-y-2 my-4">
                        <div className="profile-form">
                            <h3 className=' text-gray-800  font-semibold pb-1 text-[17px]'>Mobile No</h3>
                            <input {...register("phone", { required: true })} type='number' placeholder="Mobile/phone" className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.phone && <span className='text-red-500'>The fill is required</span>}
                        </div>
                        <div className="profile-form">
                            <h3 className=' text-gray-800  font-semibold pb-1 text-[17px]'>City Name </h3>
                            <input {...register("city", { required: true })} type='text' placeholder="city" className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.city && <span className='text-red-500'>The fill is required</span>}
                        </div>
                    </div>
                    <div className="grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-4 gap-y-2 ">
                        <div className="profile-form">
                            <h3 className=' text-gray-800  font-semibold pb-1 text-[17px]'>Zipcode</h3>
                            <input {...register("zipcode", { required: true })} type='number' placeholder="zipcode " className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.zipcode && <span className='text-red-500'>The fill is required</span>}
                        </div>
                        <div className="profile-form">
                            <h3 className=' text-gray-800  font-semibold pb-1 text-[17px]'>Occupation</h3>
                            <input {...register("occupation", { required: true })} type='text' placeholder="Occupation" className="mt-2 flex text-slate-800 w-full items-center placeholder-gray-700  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                            {errors.occupation && <span className='text-red-500'>The fill is required</span>}
                        </div>
                    </div>
                    <div className="profile-form my-4">
                        {
                            loadding ?
                                <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white w-full justify-center from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Update Saving please wait.. <span className='loader'></span></button>
                                :
                                <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white w-full justify-center from-blue-500 to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Save Update<BsArrowRightCircle /></button>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ProfileRight;