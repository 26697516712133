import React, { useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi'
import { BsArrowRightCircle, BsSearch } from 'react-icons/bs'
import { toast } from 'react-toastify';
import DashBoardLoadding from '../DashBoardLoadding/DashBoardLoadding';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';

const ManagmentProject = () => {
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')

    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/project/getAllNotice`)
            .then(res => res.json())
            .then(data => {
                setUsers(data)
            })
    }, [users])


    //Remove Single Users
    const handleDeleteUser = (id) => {
        const user = window.confirm('Do you want delete this project?');

        if (user) {
            fetch(`${backendApi}/project/removeNotice/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }



    return (
        <section className='all-users-section overflow-hidden'>
            {
                users?.length === 0 ?
                    <>
                        <h2 className=' text-blue-500 font-semibold text-[30px] mt-[130px] mb-2'>Your Total Project : {users?.length}</h2>
                        <h2 className=' text-gray-800 font-semibold text-[18px] mb-2'>Please add project</h2>
                        {/* <DashBoardLoadding /> */}
                    </>
                    :

                    <div className='my-24'>
                        <h1 className=' text-[22px] font-bold text-slate-800  mt-28 mb-[20px] text-center capitalize'> All  <span className='text-blue-500'>projects</span> List</h1>
                        {/* Search Box  */}
                        {/* Search Box  */}
                        <div className="search-box relative flex items-center my-5  w-full md:w-[25%]">
                            <input type="text" placeholder='Search Title' className='rounded-lg p-2 w-full block  relative focus:ring-2 outline-0 border border-slate-400 focus:ring-blue-500 placeholder-gray-400  text-slate-800 font-semibold' onChange={(e) => setSearch(e.target.value)} />
                            <BsSearch className='absolute right-[10px] text-gray-500' />
                        </div>

                        <div className="show-project">
                            <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5">
                                {
                                    users?.filter(item => item.title.toLowerCase().includes(search)).map((user, index) => {
                                        const { name, photo, _id, title, date } = user;
                                        return (
                                            <div className="rounded-xl border border-transparent hover:border hover:border-blue-400 p-3 shadow-xl shadow-gray-400  transition ease-in-out duration-75" key={index}>
                                                <div className="flex flex-wrap  gap-5">
                                                    <div className="index-item">
                                                        <h2 className='w-[50px] h-[50px] rounded-full mx-auto bg-blue-500 flex items-center justify-center text-white font-semibold'>{index + 1}</h2>
                                                    </div>
                                                    <div className="title-sec-project">
                                                        <h2 className='text-slate-800 text-[20px] capitalize font-semibold'>{title}</h2>
                                                        <div className="profile-admin flex flex-wrap gap-2 items-center my-2">
                                                            <img src={photo} alt="profile" className='w-[35px] h-[35px] rounded-full' />
                                                            <h2 className='text-gray-700 text-[15px] capitalize'>{name}</h2>
                                                            <h2 className='text-gray-700 text-[15px] capitalize'>{date}</h2>
                                                        </div>

                                                        <button className='flex items-center mt-[20px] md:mt-0 gap-2  bg-gradient-to-r text-[14px] font-medium text-white from-red-400 to-orange-400 hover:from-orange-500 hover:to-red-500 rounded-lg p-1  justify-center' onClick={() => handleDeleteUser(_id)}>Remove post <BsArrowRightCircle /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </section >
    );
};

export default ManagmentProject;