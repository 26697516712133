import React from 'react';
import { GiHotMeal } from 'react-icons/gi'
import { FaBook, FaPhoneAlt, } from 'react-icons/fa'
import { MdBloodtype, MdOutlineHealthAndSafety } from 'react-icons/md'
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';


const serviceData = [
    {
        title: 'Food',
        disc: 'We want to inspire a nation with food. Best relation can be created through sharing food.',
        logo: <GiHotMeal />
    },
    {
        title: 'Education',
        disc: 'To become a national treasure by offering free education to children and orphans.',
        logo: <FaBook />
    },
    {
        title: 'Treatment',
        disc: 'To deliver free medicines and health care to underprivileged people.',
        logo: <MdOutlineHealthAndSafety />
    },
    {
        title: 'blood donation',
        disc: 'We are donate free blood donation any time, just contact with us',
        logo: <MdBloodtype />
    },
    {
        title: 'Emergency service',
        disc: 'Call 01738 733 745 for any urgent needs',
        logo: <FaPhoneAlt />
    }
]

const Services = () => {
    return (
        <div className='service-section overflow-hidden my-20'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="service-title mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]  font-extrabold text-slate-800 uppercase'>our   <span className='text-blue-500'>Mission</span> </h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>What we do for our society</h2>
                    </RubberBand>
                </div>
                <div className="service-item grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-5 gap-5">
                    {
                        serviceData?.map((item, index) => {
                            const { title, logo, disc } = item;
                            return (
                                <>
                                    <Zoom>
                                        <div className="service-item rounded-xl p-2 cursor-pointer border border-transparent shadow-lg shadow-gray-400 hover:border hover:border-blue-400 transition ease-in-out duration-75 my-5" key={index}>
                                            <div className="service-main text-center">
                                                <h2 className='text-gray-200 text-[30px] text-center mx-auto justify-center flex bg-blue-400 h-[50px] w-[50px] rounded-full p-2 items-center'>{logo}</h2>
                                                <h2 className='text-slate-800 text-[20px] font-semibold  capitalize text-center py-2'>{title}</h2>
                                                <h2 className='text-gray-500 text-[16px] font-semibold '>{disc}</h2>
                                            </div>
                                        </div>
                                    </Zoom>
                                </>
                            )

                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Services;