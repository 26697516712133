import React from 'react';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import donate from '../../../../images/contribute/donate.jpg'
import fb from '../../../../images/contribute/fb.jpg'
import volanteer from '../../../../images/contribute/volanteer.png'
import { Link } from 'react-router-dom';
import { FaFacebookF, FaHandHoldingUsd, FaHandHoldingWater } from 'react-icons/fa';
import { BsArrowRightCircle } from 'react-icons/bs';



const Contribute = () => {
    return (
        <div className='overflow-hidden my-24'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="contribute-title mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]  font-extrabold text-slate-800 uppercase'>Join <span className='text-blue-500'>US</span> </h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>Anyone can join our social work</h2>
                    </RubberBand>
                </div>
                <div className="contribute-main">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">

                        <Zoom>
                            <div className="contribute-item bg-slate-200 shadow-lg my-4 border border-transparent shadow-gray-500 rounded-xl hover:border hover:border-blue-400 overflow-hidden transition ease-in-out delay-75 duration-7">
                                <img src={donate} alt="oikko" className='mx-auto rounded-t-xl hover:scale-x-150 w-full object-center	h-[200px] md:h-[250px]  transition ease-in-out delay-75 duration-75 cursor-pointer' />
                                <div className="p-3 text-center">
                                    <h2 className='text-center text-[20px]  py-2  font-semibold text-slate-950'>Donations</h2>
                                    <p className='text-gray-600 '>Oikko is always with you at any crisis.</p>
                                    <Link to='/donate'>
                                        <button className='flex items-center  justify-center gap-2 mx-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:from-orange-500 hover:to-red-500 text-[16px] my-2 font-medium   text-white  capitalize rounded-xl p-2'><FaHandHoldingUsd />Donate Now <BsArrowRightCircle /></button>
                                    </Link>
                                </div>
                            </div>
                        </Zoom>

                        <Zoom>
                            <div className="contribute-item bg-slate-200 shadow-lg my-4 border border-transparent shadow-gray-500 rounded-xl hover:border hover:border-blue-400 overflow-hidden transition ease-in-out delay-75 duration-7">
                                <img src={volanteer} alt="oikko" className='mx-auto rounded-t-xl hover:scale-x-150 w-full	h-[200px] md:h-[250px]  transition ease-in-out delay-75 duration-75 cursor-pointer' />
                                <div className="p-3 text-center">
                                    <h2 className='text-center text-[20px]  py-2  font-semibold text-slate-950'>Volunteer</h2>
                                    <p className='text-gray-600 '>Oikko will be proud to have you as a volunteer.</p>
                                    <Link to='/volunteer'>
                                        <button className='flex items-center  justify-center gap-2 mx-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:from-orange-500 hover:to-red-500 text-[16px] my-2 font-medium   text-white  capitalize rounded-xl p-2'><FaHandHoldingWater />Registration Now  <BsArrowRightCircle /></button>
                                    </Link>
                                </div>
                            </div>
                        </Zoom>

                        <Zoom>
                            <div className="contribute-item bg-slate-200 shadow-lg my-4 border border-transparent shadow-gray-500 rounded-xl hover:border hover:border-blue-400 overflow-hidden transition ease-in-out delay-75 duration-7">
                                <img src={fb} alt="oikko" className='mx-auto rounded-t-xl hover:scale-x-150 w-full	h-[200px] md:h-[250px]  transition ease-in-out delay-75 duration-75 cursor-pointer' />
                                <div className="p-3 text-center">
                                    <h2 className='text-center text-[20px]  py-2  font-semibold text-slate-950'>Join to Facebook</h2>
                                    <p className='text-gray-600 '>Join with us facebook and gets update from Oikko</p>
                                    <a href="http://www.facebook.com/groups/oikko.awo" target="_blank" rel="noopener noreferrer">
                                        <button className='flex items-center  justify-center gap-2 mx-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:from-orange-500 hover:to-red-500 text-[16px] my-2 font-medium   text-white  capitalize rounded-xl p-2'><FaFacebookF />Join Facebook  <BsArrowRightCircle /></button>
                                    </a>
                                </div>
                            </div>
                        </Zoom>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contribute;