import React from 'react';
import logo from '../../images/preload.gif'

const PreLoader = () => {
    return (
        <div className='my-32 text-center'>
            <img src={logo} alt="home-loader " className='mx-auto h-[200px] lg:h-[300px]' />
        </div>
    );
};

export default PreLoader;