import React from 'react';
import CountUp from 'react-countup';
import { FaHeart, FaHandHoldingHeart, FaUsers } from 'react-icons/fa';
import { GiLovers } from 'react-icons/gi';
import Zoom from 'react-reveal/Zoom';


const counterData = [
    {
        title: 'Completed  Projects',
        digit: 40,
        logo: <FaHeart />
    },
    {
        title: 'People Joined',
        digit: 15980,
        logo: <FaUsers />
    },
    {
        title: 'Volunteer',
        digit: 80,
        logo: <FaHandHoldingHeart />
    },
    {
        title: 'Get help from us',
        digit: 14520,
        logo: <GiLovers />
    }
]

const Counter = () => {
    return (
        <div className='counter bg-slate-800  py-8 mt-48 overflow-hidden'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-8">
                    <>
                        <Zoom>
                            {
                                counterData?.map((item, index) => {
                                    const { logo, title, digit } = item;
                                    return (
                                        <div className="counter-item " key={index}>
                                            <div className="counter-main text-center">
                                                <h2 className='text-white text-[30px] text-center mx-auto justify-center flex bg-slate-600 h-[50px] w-[50px] rounded-full p-2 items-center'>{logo}</h2>
                                                <div className="counter-sub-title">
                                                    <h2 className='text-blue-400 text-[35px] font-extrabold mt-2'><CountUp end={digit} />+</h2>
                                                    <h2 className='text-gray-400 text-[18px] font-medium '>{title}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Zoom>
                    </>
                </div>
            </div>
            {/* <div className="bg-gradient-to-r from-slate-800 via-slate-900 to-slate-700 absolute top-10 left-0 z-10 blur-[100px] w-full h-full mx-auto "></div> */}
        </div>
    );
};

export default Counter;