import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const DashBoardLoadding = () => {
    return (
        <div>
            <Skeleton
                count={1}
                baseColor="#5f6f89"
                width='100%'
                height={200}
                highlightColor="#0F172A"
                borderRadius='10px'
            />
        </div>
    );
};

export default DashBoardLoadding;