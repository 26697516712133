import React, { useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import { FaUsers } from 'react-icons/fa';
import { TbArrowWaveRightUp } from 'react-icons/tb';
import { MdOutlineReviews, MdPermContactCalendar } from 'react-icons/md';
import { TfiGallery } from 'react-icons/tfi';
import { Link } from 'react-router-dom'
import DashHomeUser from './DashHomeUser';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';



const DashHome = () => {
    const [contact, setContact] = useState([]);
    const [users, setUsers] = useState([])
    const [review, setReview] = useState([])
    const [photo, setPhoto] = useState([]);


    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/auth/allusers`)
            .then(res => res.json())
            .then(data => setUsers(data))
    }, [users])

    //load Contact Api 
    useEffect(() => {
        fetch(`${backendApi}/project/getAllNotice`)
            .then(res => res.json())
            .then(data => setContact(data))
    }, [contact])


    //load team api 
    useEffect(() => {
        fetch(`${backendApi}/team/getAllTeam`)
            .then(res => res.json())
            .then(data => setReview(data))
    }, [review])


    // load Photo API 
    useEffect(() => {
        fetch(`${backendApi}/gallery/getAllGallPhoto`)
            .then(res => res.json())
            .then(data => setPhoto(data))
    }, [photo])


    //Total array length
    const totalContact = contact?.length;
    const totalReview = review?.length;
    const totalPhoto = photo?.length;
    const totalUser = users?.length;

    //convert percent
    const contactPercent = parseInt(100 / totalContact);
    const reviewPercent = parseInt(100 / totalReview);
    const photoPercent = parseInt(100 / totalPhoto);
    const userPercent = parseInt(100 / totalUser);

    const homeSlideData = [
        {
            title: 'Total Users',
            digit: totalUser,
            logo: <FaUsers />,
            path: 'all-users',
            parcent: userPercent,
            disc: 'active users'

        },
        {
            title: 'total projects',
            digit: totalContact,
            logo: <MdPermContactCalendar />,
            path: 'project-management',
            parcent: contactPercent,
            disc: 'contact users'

        },
        {
            title: 'gallery photo',
            digit: totalPhoto,
            logo: <TfiGallery />,
            path: 'upload-gallrey',
            parcent: photoPercent,
            disc: 'project photos'

        },
        {
            title: 'total Volunteer',
            digit: totalReview,
            logo: <MdOutlineReviews />,
            path: 'add-volunteer',
            parcent: reviewPercent,
            disc: 'volunteer list'
        }

    ]


    return (
        <section className='container mx-auto my-24 px-5'>
            <div className="dash-total">
                <h2 className='text-blue-500 text-[20px] mb-5 font-bold'>Dashboard Overview</h2>
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
                    {
                        homeSlideData?.map(item => {
                            const { logo, title, digit, path, parcent, disc } = item;
                            return (
                                <Zoom>
                                    <div className="rounded-xl border border-transparent hover:border hover:border-blue-400 p-3 shadow-xl shadow-gray-400  transition ease-in-out duration-75">
                                        <Link to={`/dashboard/${path}`}>
                                            <div className="flex items-center justify-between space-x-2">
                                                <h2 className='text-slate-800 font-semibold text-[20px] capitalize'>{title}</h2>
                                                <h2 className='text-white bg-blue-400 text-[25px] p-1 flex items-center justify-center h-[50px] w-[50px] mx-auto rounded-full'>{logo}</h2>
                                            </div>
                                            <h1 className='text-slate-800 text-[30px] text-center font-bold my-2'>{digit}</h1>
                                            <h5 className='flex items-center capitalize'><TbArrowWaveRightUp className='text-green-500 font-semibold text-[18px]' /> <span className='px-2 text-blue-500'>{parcent}% </span><span className='text-slate-600'>{disc}</span></h5>
                                        </Link>
                                    </div>
                                </Zoom>
                            )
                        })
                    }
                </div>
                <div className="list-user">
                    <DashHomeUser />
                </div>
            </div>
        </section >
    );
};

export default DashHome;