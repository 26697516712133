import React, { useState } from 'react';
import { FaUsers, FaBars, FaFacebookMessenger } from 'react-icons/fa'
import { BsDatabaseCheck } from 'react-icons/bs'
import { BiMessageDots } from 'react-icons/bi'
import { MdOutlineDateRange, MdOutlineReviews, MdOutlineAdminPanelSettings } from 'react-icons/md'
import { TfiGallery } from 'react-icons/tfi'
import { NavLink } from 'react-router-dom';
import './SlideBar.css'

const slideData = [
    {
        title: 'Users List',
        logo: <FaUsers />,
        path: 'all-users'

    },
    {
        title: 'Gallery Photo',
        logo: <TfiGallery />,
        path: 'upload-gallrey'

    },
    {
        title: 'Add project ',
        logo: <MdOutlineDateRange />,
        path: 'add-project'
    },
    {
        title: 'control project',
        logo: <BsDatabaseCheck />,
        path: 'project-management'
    },
    {
        title: 'Add Volunteer',
        logo: <MdOutlineReviews />,
        path: 'add-volunteer'
    },
    {
        title: 'Volunteer Proposal',
        logo: <FaFacebookMessenger />,
        path: 'message'
    },
    {
        title: 'Contact Messages',
        logo: <BiMessageDots />,
        path: 'special-message'
    },
    {
        title: 'admin access',
        logo: <MdOutlineAdminPanelSettings />,
        path: 'admin-access'
    }
]

const SlideBar = () => {
    const [open, setOpen] = useState(true);
    const toggle = () => setOpen(!open);
    return (
        <section className='slide-section bg-slate-800 pt-[100px] pb-[10px] h-screen overflow-y-scroll' style={{ width: open ? '230px' : '80px' }}>
            <div className="flex items-center cursor-pointer gap-x-2 pl-5">
                <FaBars className='text-white cursor-pointer' onClick={toggle} />
                <h2 className='text-orange-500' onClick={toggle} style={{ display: open ? 'block' : 'none' }}>DashBoard</h2>
            </div>
            <div className="slidebar">
                {
                    slideData?.map((data, index) => {
                        const { title, path, logo } = data;
                        return (
                            <div className="slidebar-menu">
                                <NavLink to={`/dashboard/${path}`} className='flex items-center text-white my-5' key={index}>

                                    <h2 className='pl-5 flex items-center text-white my-5 capitalize font-medium text-[16px]'>{logo} <span className='pl-2' style={{ display: open ? 'block' : 'none' }}>{title}</span></h2>

                                </NavLink >
                            </div>
                        )
                    })
                }
            </div >
        </section >
    );
};

export default SlideBar;