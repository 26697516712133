import React from 'react';
import Header from './Header/Header';
import Services from './Services/Services';
import Counter from './Counter/Counter';
import Organization from './Organization/Organization';
import Contribute from './Contribute/Contribute';
import Volunteer from './Volunteer/Volunteer';
import Projects from './Projects/Projects';

const Home = () => {
    return (
        <div>
            <Header />
            <Services />
            <Organization />
            <Counter />
            <Projects />
            <Volunteer />
            <Contribute />
        </div>
    );
};

export default Home;