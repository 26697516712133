import React, { useEffect, useState } from 'react';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import TeamLoadding from '../../Animation/TeamLoadding';
import { BsSearch } from 'react-icons/bs';



const TeamMember = () => {
    const [team, setTeam] = useState([]);
    const [search, setSearch] = useState('')

    //load user api 
    useEffect(() => {
        fetch(`${backendApi}/team/getAllTeam`)
            .then(res => res.json())
            .then(data => setTeam(data))

    }, [team])




    return (
        <div className='team-section overflow-hidden my-32'>
            <div className="container mx-auto px-5 md:px-10">
                <div className="team-title mb-24">
                    <RubberBand>
                        <h2 className='text-center text-[28px]  font-extrabold text-slate-800 capitalize'>Managing team <span className='text-blue-500'>& </span> Team Members</h2>
                        <h2 className='text-center text-[20px]  font-semibold text-slate-700'>our expert team members</h2>
                    </RubberBand>
                </div>
                <div className="show-teammenber">
                    {
                        team?.length === 0 ?
                            <>
                                <TeamLoadding />
                                <div className='mt-5'></div>
                                <TeamLoadding />
                            </>
                            :
                            <section>
                                {/* Search Box  */}
                                <div className="search-box relative flex items-center my-5  w-full md:w-[25%]">
                                    <input type="text" placeholder='Search Name' className='rounded-lg p-2 w-full block  relative focus:ring-2 outline-0 border border-slate-400 focus:ring-blue-500 placeholder-gray-400  text-slate-800 font-semibold' onChange={(e) => setSearch(e.target.value)} />
                                    <BsSearch className='absolute right-[10px] text-gray-500' />
                                </div>
                                <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-5 gap-x-5 gap-y-8">
                                    {
                                        team?.filter(item => item?.name?.toLowerCase().includes(search)).map((team, index) => {
                                            const { name, photo, title } = team;
                                            return (
                                                <>
                                                    <Zoom>
                                                        <div className="team-member shadow-lg bg-slate-200 shadow-gray-400 rounded-xl cursor-pointer border border-transparent hover:border hover:border-blue-400  pb-5 transition ease-in-out duration-75 overflow-hidden" key={index}>
                                                            <div className="text-center">
                                                                <img src={photo} alt="team" className='mx-auto h-[200px] overflow-hidden object-center w-full hover:grayscale rounded-t-xl hover:rounded-xl hover:scale-x-150 hover:scale-y-150 transition ease-in-out duration-75' />
                                                                <h2 className='text-slate-800 text-[18px] mt-3 font-semibold  capitalize'>{name}</h2>
                                                                <h2 className='text-gray-500 text-[15px] font-semibold  capitalize'>{title}</h2>
                                                            </div>
                                                        </div>
                                                    </Zoom>
                                                </>
                                            )

                                        })
                                    }
                                </div>
                            </section>
                    }

                </div>

            </div>
        </div>
    );
};

export default TeamMember;