import React from 'react';
import Slider from 'react-slick';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import './Header.css'


//import header images 
import logo1 from '../../../../images/header/11.jpg'
import logo2 from '../../../../images/header/22.jpg'
import logo3 from '../../../../images/header/33.jpg'
import logo4 from '../../../../images/header/44.jpg'


const slideData = [
    {
        photo: logo1
    },
    {
        photo: logo2
    },
    {
        photo: logo3
    },
    {
        photo: logo4
    }
]


const Header = () => {

    const settings = {
        dots: true,
        infinite: true,
        // fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div className='overflow-hidden'>
            <Slider {...settings}>
                {
                    slideData?.map((item, index) => {
                        const { photo } = item;
                        return (
                            <div className="header-slide mt-[80px] " key={index}>
                                <img src={photo} alt="oikko" className='h-[570px] object-center w-full' />
                                <div className="header-title rounded-lg p-5">
                                    <Slide left>
                                        <h2 className='text-white  font-extrabold text-[30px] my-0 lg:text-[40px] capitalize'>A Welfare Organization</h2>
                                    </Slide>
                                    <Slide right>
                                        <h4 className='text-white  font-semibold  text-[20px] lg:text-[30px]'>We are here to help</h4>
                                    </Slide>

                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    );
};

export default Header;