import React from 'react';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import payment1 from '../../../images/payment/donate-1.png'
import payment2 from '../../../images/payment/donate-2.png'
import payment3 from '../../../images/payment/donate-3.png'



const Donation = () => {
    return (
        <section>
            <div className="donation">

            </div>
            <div className='donation-sec overflow-hidden my-32'>
                <div className="container mx-auto px-5 md:px-12">
                    <div className="team-title mb-24">
                        <RubberBand>
                            <h2 className='text-center text-[28px]  font-extrabold text-slate-800 uppercase'>Donate our <span className='text-orange-400'>organization</span> </h2>
                            <h2 className='text-center text-[20px]  font-semibold text-slate-700'>You can donate our organization</h2>
                        </RubberBand>
                    </div>
                    <div className="contact-main grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">

                        <Zoom>
                            <div className="donate-item">
                                <div className="shadow-lg shadow-gray-500 min-h-[200px] my-5 border border-transparent hover:border hover:border-blue-400 overflow-hidden rounded-2xl p-2 hover:cursor-pointer transition duration-700 ease-in-out">
                                    <img src={payment2} alt="bkash" className='h-[100px] rounded-full w-[100px] mx-auto' />
                                    <h2 className='text-slate-800 text-center font-semibold  text-[18px] my-1'>Donate With Bkash</h2>
                                    <h2 className=' text-gray-600 text-center  text-[16px] font-semibold'>Account : +8801738 733 745</h2>

                                </div>
                            </div>
                        </Zoom>

                        <Zoom>
                            <div className="donate-item">
                                <div className="shadow-lg shadow-gray-500 my-5 min-h-[200px] border border-transparent hover:border hover:border-blue-400 overflow-hidden rounded-2xl p-2 hover:cursor-pointer transition duration-700 ease-in-out">
                                    <div className='h-[100px] rounded-full w-[100px] mx-auto'>
                                        <img src={payment1} alt="nagod" className='h-[100px] rounded-full w-[100px] mx-auto' />
                                    </div>
                                    <h2 className='text-slate-800 text-center font-semibold  text-[18px] my-1'>Donate With Agrani Bank</h2>
                                    <h2 className=' text-gray-600 text-center  text-[16px] font-semibold'>Account : 02000 10171735</h2>
                                    <h2 className=' text-gray-600 text-center  text-[16px] font-semibold'>Branch: Salbahan hat</h2>
                                </div>
                            </div>
                        </Zoom>

                        <Zoom>
                            <div className="donate-item">
                                <div className="shadow-lg shadow-gray-500 min-h-[200px] my-5 border border-transparent hover:border hover:border-blue-400 overflow-hidden rounded-2xl p-2 hover:cursor-pointer transition duration-700 ease-in-out">
                                    <img src={payment3} alt="nagod" className='h-[100px] rounded-full w-[100px] mx-auto' />
                                    <h2 className='text-slate-800 text-center font-semibold  text-[18px] my-1'>Donate With Nagad</h2>
                                    <h2 className=' text-gray-600 text-center  text-[16px] font-semibold'>Account : +8801738 733 745</h2>

                                </div>
                            </div>
                        </Zoom>


                    </div>
                </div>
            </div>
        </section>
    );
};

export default Donation;