import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { toast } from 'react-toastify';
import useActiveUser from '../../../Hooks/useActiveUser';
import { useNavigate } from 'react-router-dom';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { BsArrowRightCircle } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';












const AddProject = () => {
    const [loadding, setLoadding] = useState(false);
    const [image, setImage] = useState('');
    const [preview, setPreview] = useState()
    const [disc, setDisc] = useState('');
    const [title, setTitle] = useState('');
    const [user] = useActiveUser();
    const imageBBAPi = 'a8debf6b1d0f4ee3bab28f8c0d4b9937'

    const navigate = useNavigate();
    const date = new Date().toDateString();


    const editor = useRef(null);





    const handleUpload = (e) => {
        setImage(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))

    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("image", image)



        // get all data form api 
        setLoadding(true)
        await fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    const imageUrl = result?.data?.url;

                    const sendData = {
                        thubnail: imageUrl,
                        title: title,
                        disc: disc,
                        photo: user?.profile,
                        name: user?.userName,
                        date: date
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendApi}/project/postNotice`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/projects')
                                setLoadding(false);
                                setImage('')
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)

                                }
                            }

                        })
                }
            })
    }



    return (
        <section className='notice-section overflow-hidden my-16'>
            <div className="container mx-auto p-5 my-12 shadow-xl rounded-xl shadow-gray-500">
                <h1 className='text-slate-800 text-[25px] font-bold text-center capitalize'> Add your<span className='text-blue-500'> Project</span></h1>
                <div className="notice-extra">
                    <form onSubmit={handleSubmit}>
                        <div className="form2">
                            <label htmlFor="title" className='font-medium text-slate-800 text-[18px] mb-2'>Title </label>
                            <input type="text" placeholder='Project Name ' id="title" onChange={(e) => setTitle(e.target.value)} className='placeholder:text-gray-500 outline-none focus:ring-2 focus:ring-blue-400 text-slate-800 bg-transparent rounded-lg block p-2 border-[1px] border-gray-500 w-full' required />
                        </div>
                        <div className="form2 my-5">
                            <label htmlFor="description" className='font-medium text-slate-800 text-[18px] mb-2'>Description </label>
                            <JoditEditor
                                ref={editor}
                                value={disc}
                                tabIndex={2}
                                onChange={newContent => setDisc(newContent)}
                            />
                        </div>
                        <div className="form2 my-5">
                            <h2 className='text-slate-800 font-semibold'>Project Photo </h2>
                            {
                                image ? <img src={preview} alt="review " className='w-auto rounded-xl h-[200px] object-cover' /> : ''
                            }
                            {
                                image ? '' : <label htmlFor="fff" className='cursor-pointer'><MdCloudUpload className='inline text-[50px] text-slate-800' /> </label>
                            }

                            <input type="file" onChange={handleUpload} className='hidden' id='fff' />
                            <br />
                        </div>

                        <div className="notice-form1 my-4">
                            {
                                loadding ?
                                    <button className='rounded-xl bg-rose-500 p-2 text-white font-semibold capitalize flex items-center gap-2' disabled>Project publishing Please wait...<span class="loader"></span> </button>
                                    :
                                    <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white from-blue-500 to-blue-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Publish Now<BsArrowRightCircle /></button>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </section >
    );
};


export default AddProject;