import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { BsArrowRightCircle } from 'react-icons/bs';
import Slide from 'react-reveal/Slide'
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { toast } from 'react-toastify';
import logo1 from '../../../../images/header/1.jpg'


const bdDivision = [
    {
        name: 'Mymensingh'
    },
    {
        name: 'Chattogram'
    },
    {
        name: 'Rajshahi'
    },
    {
        name: 'Khulna'
    },
    {
        name: 'Dhaka'
    },
    {
        name: 'Rangpur'
    },
    {
        name: 'Sylhet'
    },
    {
        name: 'Barishal'
    }
]




const Volunteer = () => {
    const [loadding, setLoadding] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const date = new Date().toDateString();

    const onSubmit = data => {
        const sendData = {
            userName: data?.userName,
            email: data?.email,
            address: data?.address,
            phone: data?.phone,
            division: data?.division,
            gender: data?.gender,
            time: date
        }
        setLoadding(true)
        fetch(`${backendApi}/message/postMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendData)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message);
                    reset()
                    setLoadding(false)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }

    return (
        <div className='overflow-hidden my-24 bg-slate-800 py-5'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5 items-center">
                    <Slide left>
                        <div className="volunteer-item">
                            <img src={logo1} alt="oikko" className='mx-auto rounded-xl' />
                        </div>
                    </Slide>

                    <Slide right>
                        <div className="volunteer-item shadow-sm shadow-gray-500 rounded-xl p-2 mb-3">
                            <h2 className='text-[30px]  font-semibold text-white mb-5'>Be  a <span className='text-orange-400'>volunteer</span></h2>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5">
                                    <div class="mb-3">
                                        <label htmlFor="nn" className='text-gray-400 font-medium '>Full Name</label>
                                        <input {...register("userName", { required: true })} type='text' id='nn' placeholder='Enter Your Name' className="mt-2 flex h-12 text-white w-full items-center placeholder-gray-500 focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.userName && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                    <div class="mb-3">
                                        <label htmlFor="mm" className='text-gray-400 font-medium '>Email Address</label>
                                        <input {...register("email", { required: true })} type='email' id='mm' placeholder='Email address' className="mt-2 flex h-12 text-white w-full items-center placeholder-gray-500 focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.email && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                </div>

                                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5">
                                    <div class="mb-3">
                                        <label htmlFor="pn" className='text-gray-400 font-medium '>Phone</label>
                                        <input {...register("phone", { required: true })} type='number' id='pn' placeholder='Mobile/Phone' className="mt-2 flex h-12 text-white w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.phone && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                    <div class="mb-3">
                                        <label htmlFor="aa" className='text-gray-400 font-medium '>Present Address</label>
                                        <input {...register("address", { required: true })} type='text' id='aa' placeholder='Your address' className="mt-2 flex h-12 text-white w-full items-center placeholder-gray-500  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.address && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                </div>

                                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-5">
                                    <div class="mb-3">
                                        <label htmlFor="gg" className='text-gray-400 font-medium '>Gender</label>
                                        <select {...register("gender", { required: true })} type='text' id='gg' placeholder='Your address' className="mt-2 flex h-12 text-white w-full items-center placeholder-gray-500 focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500">
                                            <option value="female" className='text-gray-500' selected>Select Gender</option>
                                            <option value="female" className='text-slate-800'>Female</option>
                                            <option value="male" className='text-slate-800'>Male</option>
                                            <option value="other" className='text-slate-800'>Others</option>
                                        </select>
                                        {errors.gender && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                    <div class="mb-3">
                                        <label htmlFor="dd" className='text-gray-400 font-medium '>Divisions</label>
                                        <select {...register("division", { required: true })} type='text' id='dd' placeholder='Your address' className="mt-2 flex h-12 text-white w-full  items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500">
                                            {
                                                bdDivision?.map((item, index) => {
                                                    return (
                                                        <option value={item?.name} key={index} className='text-slate-800'>{item?.name}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                        {errors.division && <span className='text-red-400 mt-1 '>This field is required</span>}
                                    </div>
                                </div>
                                <div className="submit">
                                    {
                                        loadding ? <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' disabled>Submitting wait.. <span className='loader'></span></button>
                                            :

                                            <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white from-blue-600 to-blue-500 hover:from-orange-400 hover:to-orange-500 rounded-xl p-2'>Submit Now <BsArrowRightCircle /></button>
                                    }

                                </div>
                            </form>

                        </div>
                    </Slide>
                </div>
            </div>
        </div>

    );
};

export default Volunteer;