import React from 'react';
import RubberBand from 'react-reveal/RubberBand';
import Slide from 'react-reveal/Slide';




const About = () => {
    const logo = 'https://i.ibb.co/54NPmm7/1.jpg'

    return (
        <div className='project-sec overflow-hidden mt-32 mb-36'>
            <div className="container mx-auto px-5 md:px-12">
                <div className="team-title mb-24">
                    <RubberBand>
                    <h2 className='text-center text-[28px]  font-extrabold text-slate-800 uppercase'>about   <span className='text-blue-500'>us</span></h2>
                    <h2 className='text-center text-[20px]  font-semibold text-slate-700'>Read more about our organization</h2>
                    </RubberBand>
                </div>
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-10 gap-y-5">
                    <Slide left>
                        <div className="volunteer-left">
                            <img src={logo} alt="logo" className='rounded-xl hover:grayscale' />
                        </div>
                    </Slide>

                    <Slide right>
                        <div className="volunteer-left">
                            <h2 className='text-slate-800 font-bold  text-[30px]'><span className='text-blue-500'>OIKKO</span> - A Non-profit Organization</h2>
                            <p className='text-[17px]  font-medium text-gray-500 my-4 oikko-about-title font-[Hind Siliguri sans serif]'>ঐক্য একটি স্বেচ্ছাসেবি সংগঠন। মানবতার তাগিদে "ঐক্য"র সূচনা। প্রতিষ্ঠানটি ২৭ জুন ২০১৫ সালে আনুষ্ঠানিক ভাবে যাত্রা শুরু করে। মানুষের মৌলিক ও প্রাকৃতিক বিপর্যস্ত বিভিন্ন ক্ষেত্র নিয়ে "ঐক্য" কাজ করে। মানবসেবায় "ঐক্য" দৃঢ়প্রতিজ্ঞা বদ্ধ। এছাড়াও খাদ্য,বস্ত্র,চিকিৎসা,পড়াশুনা ও প্রাকৃতিক দুর্যোগ মোকাবেলায় "ঐক্য" নিরলস কাজ করে যাচ্ছে। এমনকি জুরুরি প্রয়োজনে রক্ত সরবরাহ ও রক্ত দানে উদ্ভুদ্ধ করতে "ঐক্য" ভূমিকা রাখছে। "ঐক্য" প্রতিষ্ঠালগ্ন থেকে আজ অবধি মানব সেবামুলক বিভিন্ন প্রোগাম আয়োজন করতে সার্থক হয়েছে। সর্বোপরি মানবতার পাশে "ঐক্য" সর্বদাই সম্পূর্ণরপে নিয়োজিত। তাই মানবকল্যাণে আমাদের এই ক্ষুদ্র প্রচেষ্টাকে আরো প্রণোদিত করতে আপনার/ আপনাদের সর্বাত্মক সহযোগিতা আমাদের বিমোহিত করবে।</p>
                            <p className='text-[17px]  font-medium text-gray-500 '>Oikko is a non-Profit welfare Organization. We have started our journy from 2015. We helped more than 3000 people in whole Bangladesh. We helped poor people in every Eid, Winter Season and Flood-Victims We have more than 100 team members. All them so supportive and amazing. We are always here to help you.</p>

                            <p className='text-[17px]  font-medium text-gray-500 mt-4'>Provide support to underprivileged section of the society specially extreme poor, homeless and orphan children through quality education, nourishment & shelter to make them resources for the nation.</p>
                        </div>
                    </Slide>

                </div>
            </div >
        </div >

    );
};

export default About;