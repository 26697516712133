import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { BsArrowRightCircle } from 'react-icons/bs'
import { FaEdit } from 'react-icons/fa'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';
import { MdCloudUpload } from 'react-icons/md'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const EditVolunteer = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [gender, setGender] = useState('');
    const [preview, setPreview] = useState()
    const [loadding, setLoadding] = useState(false)
    const imageBBAPi = 'a8debf6b1d0f4ee3bab28f8c0d4b9937'
    const navigate = useNavigate();




    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    const handleUpload = (e) => {
        setImage(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append("image", image)

        // get all data form api 
        setLoadding(true)
        fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    const imageUrl = result?.data?.url;

                    const sendData = {
                        photo: imageUrl,
                        name: name,
                        title: title,
                        gender: gender
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendApi}/team/updateMember/${id}`, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            console.log("object", result);
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/dashboard/add-volunteer')
                                setLoadding(false);
                                setImage('')
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)

                                }
                            }

                        })
                }
            })
    }



    return (
        <div>
            <button onClick={onOpenModal}>
                <div className="add-contact flex items-center gap-3 rounded-lg p-2 mr-4">
                    <FaEdit className='text-gray-400 cursor-pointer text-center mx-auto' />
                </div>
            </button>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: 'customModal',
            }}>
                <div className="contact-form shadow-2xl rounded-xl p-1">
                    <h2 className='text-slate-950 text-[20px] font-semibold text-center capitalize'>update Volunteer</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="login-details">
                            <div className="login-form1 my-4">
                                <h3 className='text-slate-950 font-medium pb-[2px] text-[16px]'>Full Name</h3>
                                <input type='text' placeholder="Name" className="mt-2 flex text-slate-950 w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border boder-gray-800 bg-white/0 p-3 text-sm outline-none border-gray-500" onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div className="login-form1 my-4">
                                <h3 className='text-slate-950 font-medium pb-[2px] text-[16px]'>Position</h3>
                                <input type='text' placeholder="Position" className="mt-2 flex text-slate-950 w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" onChange={(e) => setTitle(e.target.value)} required />
                            </div>
                            <div className="login-form1 my-4">
                                <h3 className='text-slate-950 font-medium pb-[2px] text-[16px]'>Gender</h3>
                                <select name="" className="mt-2 flex text-slate-950 w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" onChange={(e) => setGender(e.target.value)}>
                                    <option selected>Select Gender</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            {
                                image ? <img src={preview} alt="review " className='w-auto rounded-lg h-[200px] mx-auto' /> : ''
                            }
                            <div className="login-form1 my-2">
                                {
                                    image ? '' : <label htmlFor="fff" className='cursor-pointer'><MdCloudUpload className='flex items-center justify-center text-[50px] mx-auto my-3' /> </label>
                                }

                                <input type="file" onChange={handleUpload} className='hidden' id='fff' />
                            </div>
                            <div className="register-form1 my-4">
                                {
                                    loadding ?
                                        <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white w-full justify-center from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Update Saving please wait.. <span className='loader'></span></button>
                                        :
                                        <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white w-full justify-center from-blue-500 to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Save Update<BsArrowRightCircle /></button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default EditVolunteer;