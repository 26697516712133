import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './NavBar.css'
import oikko from '../../../images/logo/1.png'
// import bar from '../../../images/bars.svg'
import { GoTriangleDown } from 'react-icons/go'
import { FaBars } from 'react-icons/fa'
import useActiveUser from '../../../Hooks/useActiveUser';
import { toast } from 'react-toastify';

const NavBar = () => {
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => setOpen(!open);

    const navigate = useNavigate();

    const handleShowProfile = () => setIsOpen(!isOpen);

    const [user] = useActiveUser();


    //Handle Log Out 
    const handlelogOut = () => {
        localStorage.removeItem('token');
        navigate('/');
        toast.success('Logout successfully Done')
    }

    return (
        <section className='relative overflow-hidden'>
            <nav className='lg:flex lg:items-center lg:justify-between lg:px-12 bg-white shadow-sm shadow-gray-400 fixed top-0 right-0 left-0 z-40'>
                <div className="logo flex items-center justify-between ">
                    <Link to='/'>
                        <img src={oikko} alt="oikko" className='h-[80px] pl-10 py-1' />
                    </Link>
                    <span className='cursor-pointer' >
                        <FaBars className='lg:hidden pr-10 text-[70px]' onClick={handleToggle} />
                    </span>
                </div>
                <div className="menu">
                    <ul className={`lg:flex lg:items-center pl-10 bg-gray-300 lg:bg-transparent transition ease-in-out duration-75 ${open ? 'lg:block' : 'hidden'}`}>
                        <li>
                            <Link to='/' onClick={handleToggle}>Home</Link>
                        </li>
                        <li>
                            <NavLink to='/about' onClick={handleToggle}>About Us</NavLink>
                        </li>
                        <li>
                            <NavLink to='/donate' onClick={handleToggle}>Donate</NavLink>
                        </li>
                        <li>
                            <NavLink to='/team-members' onClick={handleToggle}>Team Members</NavLink>
                        </li>
                        <li>
                            <NavLink to='/gallery' onClick={handleToggle}>Gallery</NavLink>
                        </li>
                        <li>
                            <NavLink to='/projects' onClick={handleToggle}>Projects</NavLink>
                        </li>
                        <li>
                            <NavLink to='/volunteer' onClick={handleToggle}>volunteer</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contact' onClick={handleToggle}>Contact</NavLink>
                        </li>
                        <li className='relative'>
                            {
                                user?.email ?
                                    <div className="div relative">
                                        <span className='flex items-center gap-x-[2px] cursor-pointer relative' onClick={handleShowProfile}>
                                            <img src={user?.profile} alt="profile" className='h-8 w-8 rounded-full' /><GoTriangleDown className='text-gray-800' />
                                        </span>

                                        <div className={`absolute top-[60px] -left-[40px] rounded-lg bg-slate-800 transition-all ease-in-out duration-75 ${isOpen ? 'md:block' : 'hidden'}`}>
                                            <div className="profile-design text-center">
                                                <img src={user?.profile} alt="profile" className='w-[50px] h-[50px] mx-auto rounded-full mt-2' />
                                                <h2 className="text-gray-300 text-[15px] my-2 font-[200] capitalize">{user?.userName}</h2>
                                            </div>
                                            <hr className='bg-gray-400' />
                                            <ul>
                                                <li>
                                                    <NavLink to="/profile" className="text-gray-300 rounded-md px-3 py-2 text-[15px]  font-[200]  capitalize" onClick={handleShowProfile}>Profile</NavLink>
                                                </li>
                                                <hr className='bg-gray-400' />
                                                {
                                                    user?.role === 'admin' ?
                                                        <li>
                                                            <NavLink to="/dashboard" className="text-gray-300 rounded-md px-3 py-2 text-[15px] font-[200] capitalize" onClick={handleShowProfile}>dashboard</NavLink>
                                                        </li>
                                                        :
                                                        ''
                                                }
                                                <hr className='bg-gray-400' />

                                                <li>
                                                    <h3 className="text-gray-300 rounded-md px-3 py-2 text-[15px] cursor-pointer " onClick={handlelogOut} onClick={handleShowProfile}>Logout</h3>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    :

                                    <NavLink to="/login" className="text-white rounded-md p-2 text-[17px] font-medium bg-orange-500 flex items-center gap-x-[2px]" onClick={handleToggle}>Login</NavLink>
                            }
                        </li>

                    </ul>
                </div>
            </nav >
        </section>
    );
};

export default NavBar;