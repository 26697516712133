import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { BsArrowRightCircle } from 'react-icons/bs'
import { MdPreview } from 'react-icons/md';







const MessageModal = ({ disc }) => {
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);





    return (
        <div>
            <button onClick={onOpenModal} className='bg-blue-500 rounded-md text-white p-2'>
               Full Message
            </button>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: 'customModal',
            }}>
                <div className="read-message">
                    <h2 className='text-[20px] text-center rounded-xl p-2 font-semibold text-blue-500'>Read Full Message</h2>
                    <h2 className='text-[18px] text-center font-semibold mt-2 text-slate-800'>{disc}</h2>
                </div>
            </Modal >
        </div >
    );
};

export default MessageModal;